@import "../variables";
@import "../template/mixin_templates";
@import "../../foundation/functions";

/* Général */
body {
    background-color: $body_background_color;
    color: $texte-couleur;
}

.wrapper {
    background: $content_background_color;
}

.wrapper-content {
    h1, h2, h3,
    .h1, .h2, .h3 {
        &:not(.title_section):not(.faq_question):not(.slogan):not(.titre-etiquette) {
            background-color: $fond_titre_color;
            color: $titre_color;
        }
        a {
            color: inherit;
        }
    }
}

.modif-design-color-container{
    h2, .h2{
        background-color: $fond_titre_color;
        color: $titre_color;
        a {
            color: inherit;
        }
    }
}

a {
    color: $texte_lien;
    &:hover {
        color: $texte_lien_hover;
    }
}

/* site en deux colonnes */

.sidebar {

}

/* Boutons */

.bouton, .button, input[type="submit"], .form-newsletter input[type="submit"],
body .template_fiche_produit_3 #fiche-produit .wrap-description .side-tabs a,
body .accueil_diaporama_template .slide .slide-text .content-slide-text > a.slider-see-more {
    border-color: $button_border;
    background-color: $button_background;
    color: $button_color;
    background-image: none !important;
    &:hover, &:focus {
        color: $button_color_hover;
        border-color: $button_border_hover;
        background-color: $button_background_hover;
        &::before, &::after {
            color: $button_color_hover;
        }
    }
}

/* Header */

header {
    background-color: $header_background;
}

.slogan {
    color: $slogan_color_texte;
}

#bloc-fil-title {
    &, &::before {
        background: $blocFil;
    }
    * {
        color: $blocFilText;
    }
}

/* Menu */
nav#nav-principal {
    ul {
        background-color: $nav_background_color;
        ul {
            border-color: $nav_border_color;
            &::before, ul::before {
                border-color: $nav_border_color;
            }
        }
    }
    .navigation_link_p:not(.menu_burger){
        color: $nav_texte_color;
    }
    .navigation_button_p{
        background-color: $li_background_color;
        &.actif, &:hover, &.sousmenu_ouvert{
            background-color: $li_background_color_hover;
            > .navigation_link_p {
                color: $nav_texte_color_hover;
            }
        }
    }
    .sous_navigation_link_p{
        color: $sous_nav_texte_color;
    }
    .sous_navigation_button_p{
        background-color: $sous_li_background_color;
        &.actif, &:hover, &.sousmenu_ouvert{
            background-color: $sous_li_background_color_hover;
            > .sous_navigation_link_p {
                color: $sous_nav_texte_color_hover;
            }
        }
    }
    > ul > li > ul > li:hover > ul.menu-protection-droite::before {
        border-left-color: $nav_border_color;
    }
}

/* Produits */

.produits, .template_boutique .produits, .template_boutique_all .produits:hover, .template_boutique_all .produits, #fiche-produit .template_boutique .produits {
    background: $produit_background;
    .mask {
        a {
            background: $mask_background;
            color: $mask_lien_color;
        }
    }

    a.zoom .zoom-inner, a.zoom:hover .zoom-inner {
        background: $mask_background;
        color: $mask_lien_color;
        border-color: $border-addbasket-zoom;
    }
    .inside-hors-stock {
        background: $mask_background;
    }
    a.zoom:hover .zoom-inner, a.addbasket:hover .inside-addbasket {
        color: $mask_lien_color_hover;
    }
    .remise {
        background: $remise_background_color;
        color: $remise_texte_color;
    }
}

.produits, .template_boutique .produits, #fiche-produit .template_boutique .produits {
    a.addbasket .inside-addbasket {
        border-color: $button_border;
        background-color: $button_background;
        color: $button_color;
    }
    a.addbasket:hover .inside-addbasket, a.addbasket:focus .inside-addbasket {
        color: $button_color_hover;
        border-color: $button_border_hover;
        background-color: $button_background_hover;
    }
}

.produit_etiquette, .produit_etiquette2, .produit_etiquette3 {
    background: $etiquette_produit_background;
    h3, .h3 {
        color: $etiquette_produit_texte;
    }
}

.produit_etiquette::after, .produit_etiquette2::after, .produit_etiquette3::after {
    border-top-color: $etiquette_produit_background;
}

#fiche-produit .remise-produit {
    background-color: $etiquette_produit_background;
    color: $etiquette_produit_texte;
}

/* Fiche produit */

#fiche-produit {
    .in-onglet {
        border-color: $fiche_prod_border_color;
    }
}

/* Galerie photo */

.galerie {
    .mask {
        background: transparent;
    }
    .addcommentaires {
        color: $mask_lien_color;
    }
    //.button_galerie, .commentP, .center-img .mask{
    .button_galerie, .addcommentaires, .commentP, .bloc-titre-galerie {
        background-color: $content_background_color !important;
        color: $texte-couleur;
    }
}

/* Livre d'or */

body .template_livreor_1 .message_lo,
body .template_livreor_2 .list-messages .message_lo .IDLO,
body .template_livreor_2 .list-messages .message_lo .contenuMsgLO,
body .template_livreor_2 .list-messages .message_lo .contenuMsgLO .blcFleche::before,
body .template_livreor_2 .list-messages .message_lo:hover .IDLO,
body .template_livreor_2 .list-messages .message_lo:hover .contenuMsgLO,
body .template_livreor_2 .list-messages .message_lo:hover .contenuMsgLO .blcFleche::before {
    border-color: $message_lo_color;
}

body .template_livreor_2 .list-messages .message_lo .contenuMsgLO .blcFleche::before,
body .template_livreor_2 .list-messages .message_lo:nth-child(2n+1) .contenuMsgLO .blcFleche::before,
body .template_livreor_2 .list-messages .message_lo:hover .contenuMsgLO .blcFleche::before,
body .template_livreor_2 .list-messages .message_lo:nth-child(2n+1):hover .contenuMsgLO .blcFleche::before {
    background-color: $message_lo_color;
}

.template_livreor h4, .template_livreor .h4, .template_livreor .note,
.template_livreor_3 .list-messages .message_lo .contenuMsgLO .message::before,
.template_livreor_3 .list-messages .message_lo .contenuMsgLO .message::after {
    color: $message_lo_color;
}

/* Blog / Boutons */

.pagination {
    > li > a {
        background-color: $btn_background_color;
        color: $btn_texte_color;
    }
    li.active a {
        background-color: $btn_background_color_hover;
        border-color: $btn_background_color_hover;
        color: $btn_texte_color_hover;
        &:hover {
            background-color: $btn_background_color_hover;
            border-color: $btn_background_color_hover;
            color: $btn_texte_color_hover;
        }
    }
}

/* Slider */

body .accueil_diaporama_template {
    .slide .slide-text {
        background-color: $content_background_color;
        * {
            color: $texte-couleur;
        }
        .content-slide-text > p {
            color: lighten($texte-couleur, 20%);
            &:first-line {
                color: $texte-couleur;
            }
        }
    }
    .bx-controls-direction > a, .slide > div .button_slider, .slide > li .button_slider {
        background-color: $content_background_color;
        color: $texte-couleur;
    }
    .bx-wrapper .bx-pager {
        .bx-pager-link {
            border-color: $content_background_color;
            &:hover, &:focus {
                background-color: lighten($content_background_color, 20%);
            }
            &.active:hover, &.active {
                background-color: $content_background_color;
            }
        }
    }

    &.accueil_diaporama_template_1, &.accueil_diaporama_template_3 {
        .bx-wrapper .bx-pager {
            .bx-pager-link {
                border-color: $diapo_rond_border;
                &:hover, &:focus {
                    background-color: $diapo_rond_hover_border;
                }
                &.active:hover, &.active {
                    background-color: $diapo_rond_border;
                }
            }
        }
    }
}

.produits-accueil.template_boutique_accueil.accueil_boutique_template_1 .bx-wrapper .bx-controls-direction > a,
.banner_diaporama .bx-wrapper .bx-controls-direction > a,
#fiche-produit .previews span,
.content-slider > .bx-wrapper .bx-controls-direction > a {
    background-color: $slider_rond_border-background;
    color: $slider_texte_color;
}

.produits-accueil.template_boutique_accueil.accueil_boutique_template_1 .bx-wrapper .bx-pager .bx-pager-link,
.banner_diaporama .bx-wrapper .bx-pager .bx-pager-link,
.content-slider > .bx-wrapper .bx-pager .bx-pager-link {
    border-color: $diapo_rond_border;
    &:hover, &:focus {
        background-color: $diapo_rond_hover_border;
    }
    &.active:hover, &.active {
        background-color: $diapo_rond_border;
    }
}

/* Footer */

footer {
    background-color: $footer_background_color;
    color: $footer_texte_color;
    a, a:hover, p {
        color: $footer_texte_color;
    }
}

/* Responsive */

@media #{$medium-and-down} {
    nav#nav-principal > ul ul li,
    nav#nav-principal .sous_navigation_button_p {
        background: $li_background_color_hover;
        a:not(.menu_burger), > span {
            color: $nav_texte_color_hover;
        }
    }
    .menu_burger {
        background: $burger_background_color;
        color: $couleur_secondaire;
        &::before, &::after {
            background-color: $burger_texte_color;
        }
    }
    .nav_ouverte .menu_burger {
        background: $burger_texte_color;
        &::before, &::after {
            background-color: $burger_texte_color;
        }
    }
    .dropDown {
        color: $nav_texte_color;
    }
    nav#nav-principal > ul > li.active, nav#nav-principal > ul > li.sousmenu_ouvert, nav#nav-principal > ul > li:hover {
        background: $li_background_color_hover;
        > .dropDown, > a {
            color: $nav_texte_color_hover;
        }
    }
    header nav#nav-principal > ul li ul li:hover > ul::before, header nav#nav-principal > ul li ul li > ul::before {
        border-color: transparent;
        border-bottom-color: $nav_border_color;
    }
}

/* Template Panier */

.template-panier {
    background-color: $panier-background-color;
    color: $panier-titre-color;
    .connexion p {
        border-color: $panier-titre-color;
        &, a {
            color: $panier-titre-color;
        }
    }
    #header-panier {
        color: $panier-titre-color;
    }
    #panier #paniercontent {
        background-color: $panier-content-background-color;
        &, #paniertable {
            color: $panier-texte-color;
        }
        border-color: $panier-background-color;
    }
    &.template-panier-1 {
        #panier #paniercontent {
            &, #paniertable {
                color: $panier-titre-color;
            }
        }
    }
}

.button.button-secondary {
    background: orange;

    &:hover {
        background: #444;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
    background: $couleur_secondaire;
    border-color: $couleur_secondaire;

    a {
        color: #fff;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
    border-color: $couleur_secondaire;
    color: $couleur_secondaire;

    a, span {
        color: $couleur_secondaire;
    }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
    color: #000;
}